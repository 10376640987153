.event-list-container {
    padding: 20px;
    border-bottom: 3px solid yellow;
}

.event-list-card {
    position: relative;
    overflow: hidden;
    border: none;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: black;
    border: linear-gradient(45deg, dodgerblue, #fbf820) 1;
    color: white;
}

.event-list-image-container {
    width: 100%;
    height: 200px; /* Adjust the height as needed */
    overflow: hidden;
    position: relative;
}

.event-list-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
}

.card-body {
    padding: 15px;
}

.card-title {
    font-size: 1.25rem;
    margin-bottom: 10px;
}

.event-list-title {
    color: white;
    background: black;
    padding: 10px;
    font-family: "Roboto Slab", serif;
    font-optical-sizing: auto;
    font-weight: 900;
    font-style: normal;
    border-bottom: 3px solid yellow;
}

.event-list-btn {
    background-color: black;
    color: yellow;
    border: 3px solid white;
}

