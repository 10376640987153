.EC-card {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    background-color: black;
    color: white;
    border-width: 3px;
    border-style: solid;
    border-image: linear-gradient(45deg, dodgerblue, #fbf820) 1;
}

.ct {
    color: white;
}

.explore-cards-row {
    padding-top: 2em;
}