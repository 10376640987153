.user-profile-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    padding-top: 200px;
    border: 1px solid #ddd;
    border-radius: 10px;
    background-color: #0D0D0D;
}

.profile-header {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.profile-picture {
    width: 150px !important;
    height: 150px !important;
    /* border-radius: 50%; */
    margin-right: 20px;
    border-image: linear-gradient(45deg, dodgerblue, #fbf820) 1;
}

.profile-bio h2 {
    margin: 0;
    font-size: 24px;
}

.profile-bio p {
    margin: 5px 0 0;
}

.profile-tabs {
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;
}

.tab-button {
    padding: 10px 20px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: black;
    cursor: pointer;
    transition: all 0.3s ease;
    color: white;
}

.tab-button.blk-bkg {
    background-color: black;
    color: white;
    border-width: 3px;
    border-style: solid;
    border-image: linear-gradient(45deg, dodgerblue, #fbf820) 1;
}

.tab-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.blk-bkg {
    background: black;
    color: white;
}

.color-white {
    color: white;
}

.up-edit-icon {
    /* margin-left: 20px; */
}

.mc {
    width: 100%;
}

.up-loader {
    padding-top:2em;
}
