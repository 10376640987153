.calendar-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    padding-top: 200px;
    padding-bottom: 100px;
}

.favorite-events {
    display: flex;
    flex-direction: column;
    gap: 20px; /* Space between cards */
    width: 100%;
}

.calendar-card {
    position: relative;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 10px;
    overflow: hidden;
    width: 100%;
    max-width: 600px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    margin: 10px auto;
    display: flex;
    flex-direction: column;
}

.calendar-image {
    width: 100%;
    height: 200px;
    object-fit: cover;
}

.calendar-details {
    padding: 15px;
}

.calendar-name {
    font-size: 1.5em;
    margin: 0;
}

.calendar-date,
.calendar-price {
    font-size: 1em;
    margin: 10px 0;
}

.calendar-loader {
    padding-top: 200px;
}
