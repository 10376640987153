.username-invisible-text {
    color: #fbf820;
    font-family: "Roboto Slab", serif;
    font-weight: bold;
    font-size: 20px;
    background: black;
    padding: 0 10px;
}

.register-login-btn:hover {
    background: #fbf820;
    color: dodgerblue;
}