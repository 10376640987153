/* .header-nav {
    position: relative;
    background-color: black;
    padding: 10px 20px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    z-index: 1000;
}

.login-logo-img {
    height: 50px;
}

.header-alert {
    background-color: black;
    text-align: center;
    padding: 5px 0;
    font-size: 14px;
}

.search-row {
    margin-top: 15px;
    position: relative;
}

.input-group {
    width: 100%;
}

.home-search {
    flex: 1;
}

.input-group-text {
    background-color: #fff;
    border-left: none;
}

.search-dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    border-radius: 4px;
    margin-top: 5px;
    z-index: 1000;
}


.search-dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    border-radius: 4px;
    margin-top: 5px;
    z-index: 1000; 
}


.modal {
    z-index: 2000 !important;
}

.modal-backdrop {
    z-index: 1999 !important; 
}


.search-dropdown ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.search-dropdown li {
    display: flex;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid #f1f1f1;
}

.search-dropdown li:hover {
    background-color: #fefee8;
}

.profile-pic {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
}

.username, .event-name {
    margin-right: 10px;
    cursor: pointer;
    font-size: 20px;
    font-family: "Roboto Slab", serif;
    font-optical-sizing: auto;
    font-weight: 900;
    font-style: normal;
}

.username:hover, .event-name:hover {
    text-decoration: underline;
}

.fa-heart, .fa-user-plus {
    cursor: pointer;
}

.fa-heart:hover, .fa-user-plus:hover {
    color: #007bff;
}

.pagination {
    display: flex;
    justify-content: space-between;
    padding: 10px;
}

.pagination button {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 4px;
}

.pagination button:hover {
    background-color: #0056b3;
}

.searched-user-events {
    background: #fefee8;
    margin-top: 1em;
} */

.header-nav {
    position: relative;
    background-color: black;
    padding: 10px 20px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    z-index: 1000;
}

.login-logo-img {
    height: 50px;
}

.header-alert {
    background-color: black;
    text-align: center;
    padding: 5px 0;
    font-size: 14px;
}

.search-row {
    margin-top: 15px;
    position: relative;
}

.input-group {
    width: 100%;
}

.home-search {
    flex: 1;
}

.input-group-text {
    background-color: #fff;
    border-left: none;
}

.search-dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    border-radius: 4px;
    margin-top: 5px;
    z-index: 1000;
    /* max-height: 300px; */
    overflow-y: auto; /* Add scroll for overflow */
}

.search-dropdown ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.search-dropdown li {
    /* padding: 10px; */
    border: 3px solid black;
}

.search-dropdown li:hover {
    background-color: #fefee8;
}

.profile-pic {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
}

.username, .event-name {
    cursor: pointer;
    font-size: 20px;
    font-family: "Roboto Slab", serif;
    font-optical-sizing: auto;
    font-weight: 900;
    font-style: normal;
}

.username:hover, .event-name:hover {
    text-decoration: underline;
}

.icon-container {
    display: flex;
    gap: 10px;
    margin-left: auto;
}

.fa-heart, .fa-user-plus {
    cursor: pointer;
    font-size: 1.2rem;
}

.fa-heart:hover, .fa-user-plus:hover {
    color: #007bff;
}

.pagination {
    display: flex;
    justify-content: space-between;
    padding: 10px;
}

.pagination button {
    background-color: black;
    color: yellow;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 4px;
    font-family: "Roboto Slab", serif;
    font-optical-sizing: auto;
    font-weight: 900;
    font-style: normal;
}

.pagination button:hover {
    background-color: black;
}

.searched-user-events {
    background: #fefee8;
    margin-top: 1em;
    /* padding: 10px; */
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 10px; /* Add spacing between elements */
}

.modal {
    z-index: 2000 !important;
}

.modal-backdrop {
    z-index: 1999 !important; 
}

.search-user-box {
    background: black;
    color: white;
    padding: 10px;
}

.search-event-box {
    padding: 15px;
}






