.trending-events-container {
    padding: 20px;
    background-color: #fefee8;
}

.trending-events-card {
    position: relative;
    overflow: hidden;
    border: none;
    border-radius: 8px;
    border: 5px solid black;
}

.card-img-container {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.trending-events-image {
    width: 100%;
    height: auto;
    object-fit: cover;
}

.likes-overlay {
    position: absolute;
    bottom: 10px;
    right: 10px;
    font-size: 1.5rem;
    background: rgba(0, 0, 0, 0.7);
    padding: 5px 10px;
    border-radius: 5px;
    color: white;
    z-index: 10; /* Ensure it appears above the image */
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
}

.trending-events-card:hover .overlay {
    opacity: 1;
}

.details-button {
    margin-top: 10px;
}

.top5-title {
    color: black;
    margin-bottom: 0;
    padding: 10px;
    font-family: "Roboto Slab", serif;
    font-optical-sizing: auto;
    font-weight: 900;
    font-style: normal;
    background: #fbf820;
}
