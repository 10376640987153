.events-container {
    display: flex;
    overflow-x: auto;
    padding: 20px;
    gap: 20px;
    background-color: black;
    border-bottom: 3px solid yellow;
}

.event-card {
    position: relative;
    background-color: #fff;
    border: 5px solid black;
    border-radius: 10px;
    overflow: hidden;
    width: 300px;
    min-width: 300px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    margin: 10px 0;
    flex-shrink: 0;
}

.event-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.event-details {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.5);
    color: #fff;
    padding: 10px;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.event-name {
    font-size: 1.2em;
    margin: 0;
}

.event-date, .event-price {
    font-size: 1em;
    margin: 5px 0;
}

.buy-tickets-button {
    display: flex;
    justify-content: center;
    gap: 50px; /* Space between icons */
    margin: 10px 0;
    width: 100%;
    background: black;
    padding: 10px;
    border-radius: 30px;
}

.event-icon {
    font-size: 1.5em;
    color: white;
    cursor: pointer;
}

.event-icon:hover {
    color: #0056b3;
}

.event-icon.liked {
    color: red;
}

.tm-rap-rnb-title {
    color: white;
    background: black;
    padding: 10px;
    font-family: "Roboto Slab", serif;
    font-optical-sizing: auto;
    font-weight: 900;
    font-style: normal;
    border-top: 3px solid yellow;
    border-bottom: 3px solid yellow;
}

