.bee-loader-container {
    background-image: url('https://i.ibb.co/b2rB4yP/beebackground.png');
    height: 100vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .bee-loader {
    /* border-top: 5px solid white;
    border-bottom: 5px solid white; */
  }
  
  .bee-icon {
    font-size: 50px;
    color: #fbf820;
  }
  
  .loader-title {
    color: #fbf820;
    font-family: "Roboto Slab", serif;
    font-optical-sizing: auto;
    font-weight: 900;
    font-style: normal;
}

.loader-card {
    background: #117ce9;
    box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
}

.loader-description {
  color: white;
  font-family: "Roboto Slab", serif;
  font-optical-sizing: auto;
  font-weight: 900;
  font-style: normal;
}

.loader-row {
  padding: 0 20px;
}

  
  
  