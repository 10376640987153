.myevents-container {
    padding: 20px;
    padding-right: 0px;
    padding-top: 0px;
}

.horizontal-scroll {
    display: flex;
    overflow-x: auto;
    gap: 20px;
}

.my-event-card {
    flex: 0 0 auto;
    display: flex;
    flex-direction: row;
    width: 300px; /* Adjust the width as needed */
    margin-bottom: 20px;
    border: 1px solid #ddd;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.event-image {
    cursor: pointer;
    max-height: 200px;
    object-fit: cover;
    width: 100%;
}

.card-body {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 20px;
}

.icon-overlay {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    gap: 20px; /* Spacing between icons */
    padding: 10px 20px;
    background-color: black; /* Black background with transparency */
    border-radius: 30px;
}

.event-icon {
    color: white;
    font-size: 24px;
}

/* Modal */
.modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0,0,0,0.9);
}

.modal-content {
    background-color: #fefefe;
    margin: auto;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
    max-width: 500px;
    max-height: 90%;
    overflow-y: auto;
    position: relative;
}

.close {
    position: absolute;
    top: 15px;
    right: 35px;
    color: #f1f1f1;
    font-size: 40px;
    font-weight: bold;
    transition: 0.3s;
    cursor: pointer;
}

.close:hover,
.close:focus {
    color: #bbb;
    text-decoration: none;
    cursor: pointer;
}

.my-events-title {
    color: white;
    background: black;
    padding: 10px;
    font-family: "Roboto Slab", serif;
    font-optical-sizing: auto;
    font-weight: 900;
    font-style: normal;
    border-bottom: 3px solid yellow;
    margin-bottom: 1em;
}
