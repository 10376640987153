@media (max-width: 575.98px) {
    .navbar-expand {
      flex-wrap: nowrap;
      justify-content: space-between;
    }
  }

.home-bottom-nav {
    background-color: black;
}

.nav-icon {
    /* color: #fbf820; */
    color: black;
    font-size: 20px;
    background: linear-gradient(135deg, dodgerblue, #FBF820);
    padding: 10px;
    border-radius: 30px;
}