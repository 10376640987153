.login-container {
    background-image: url('https://i.ibb.co/b2rB4yP/beebackground.png');
    /* <a href="https://ibb.co/k9h8Lrx"><img src="https://i.ibb.co/b2rB4yP/beebackground.png" alt="beebackground" border="0"></a> */
    height: 100vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.login-title {
    color: #fbf820;
    font-family: "Roboto Slab", serif;
    font-optical-sizing: auto;
    font-weight: 900;
    font-style: normal;
}

.login-card {
    background: #117ce9 !important;
    box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
}

.login-btn, .login-btn:hover {
    background: #fbf820 !important;
    border: 3px solid #fbf820 !important;
    font-family: "Roboto Slab", serif;
    font-optical-sizing: auto;
    font-weight: 900;
    font-style: normal;
    color: #117ce9;
    box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
}

.login-description {
    color: white;
    font-family: "Roboto Slab", serif;
    font-optical-sizing: auto;
    font-weight: 900;
    font-style: normal;
}

.create-account {
    color: #fbf820;
    font-family: "Roboto Slab", serif;
    font-optical-sizing: auto;
    font-weight: 900;
    font-style: normal;
}

.ipt {
    color: #117ce9;
    font-family: "Roboto Slab", serif;
    font-optical-sizing: auto;
    font-weight: 900;
    font-style: normal;
}


