.input-group {
    position: relative;
}

.input-group .form-control {
    padding-right: 2.5rem;
    border-radius: 30px !important;
    border: linear-gradient(135deg, dodgerblue, #FBF820);
}

.input-group .input-group-text {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background: transparent;
}

.input-group .fa-search {
    color: black; 
}

.home-search {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.home-container {
    background: #0D0D0D;

}

input.form-control.home-search {
    border-radius: 30px !important;
}

.home-content-subcontainer {
    padding-bottom: 80px; 
    padding-top: 118px;
  }
  
.home-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.search-row {
    background: black;
    padding: 10px;
}
  
